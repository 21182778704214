<template>
  <div class="main-inner-wrap" v-if="isLoading">
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
  </div>
  <div class="main-inner-wrap" v-if="!isLoading">
    <div v-auto-animate class="flex flex-col items-center mt-8 animated fade-in-up animation-delay">
      <div class="flex flex-row justify-start w-full px-2 mb-4 text-sm font-semibold text-black cursor-pointer md:hidden align-start">
        <h1 @click="$router.push('/poems')" id="singleLectureBackButton">&lt; <span class="border-b-2 border-black border-solid">Back</span></h1>
      </div>
      <article class="w-full max-w-3xl my-2 text-left bg-white border border-gray-300 rounded-xl p-7 md:px-12 md:py-10 shadow-sm">
        <div class="flex flex-col-reverse md:flex-row md:mt-0">
          <div class="text-lg font-semibold text-gray-900 sm:text-xl text-pretty">{{ selectedPoem.title }}</div>
          <div v-if="selectedPoem?.cme_credits?.length === 0 || !hasCompletedAccountSetup" class="mb-4 ml-auto md:mb-0 min-w-[132px]">
            <Button label="Claim Credit" icon="fas fa-award" class="p-button-sm p-button-secondary" @click="openClaimPoemCmeCreditDialog" id="claimCmeCreditButton" />
          </div>
        </div>
        <template v-if="!loggedInUser">
          <div class="relative flex flex-col grow">
            <div class="my-4">
              <div class="text-base font-semibold">Clinical Question</div>
              <div class="text-sm">
                {{ selectedPoem.clinical_question }}
              </div>
            </div>
            <div class="w-full h-full p-4 rounded-md bg-gray-50 blur-sm">
              <div class="h-full break-all">
                {{ selectedPoem.html }}
              </div>
            </div>
            <div class="absolute z-50 max-w-xl px-6 m-auto text-center bg-white border border-gray-500 top-40 rounded-xl py-9 -right-6 -left-6 sm:right-0 sm:left-0 sm:p-9 w-fit shadow-sm">
              <div class="max-w-lg text-black bg-white animated fade-in-up rounded-2xl">
                <h1 class="mb-3 text-xl font-bold">Sign up to view</h1>
                <p class="w-full mb-8 text-xs">To view POEMs and claim CME credits, sign up for your free account.</p>
                <div class="flex flex-col w-full max-w-lg px-2 text-center text-gray-600 text-md">
                  <RegistrationForm :loggedInUser="loggedInUser" :queryProps="{ redirectFromRegistration: `/poem/${selectedPoem.id}` }" />
                  <div class="mt-4 text-sm font-bold font-display">Already have an account? <span class="cursor-pointer text-primary" @click="redirectToLogin">Log in here</span>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="my-2" v-html="selectedPoem.html"></div>
          <div class="flex flex-row">
            <div class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border cursor-pointer flew-row rounded-xl hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="togglePoemLikeOnSubmit(selectedPoem)" :id="`poem-like-button-${selectedPoem.id}`">
              <i class="pr-3 pi" :class="selectedPoem?.likes?.length > 0 ? 'pi-thumbs-up-fill' : 'pi-thumbs-up'"></i>
              <span class="text-sm font-base">{{ selectedPoem.likes_count }}</span>
            </div>
            <div class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border cursor-pointer flew-row rounded-xl hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="togglePoemBookmarkOnSubmit(selectedPoem)" :id="`poem-bookmark-button-${selectedPoem.id}`">
              <i class="pi" :class="selectedPoem?.bookmarks?.length > 0 ? 'pi-bookmark-fill' : 'pi-bookmark'"></i>
            </div>
            <div class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border cursor-pointer flew-row rounded-xl hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="shareOnClick($event, selectedPoem)" :id="`poem-share-button-${selectedPoem.id}`">
              <i class="pr-0 pi pi-send sm:pr-2"></i>
              <span class="hidden text-sm font-base sm:block">Share</span>
            </div>
            <div v-if="selectedPoem?.cme_credits?.length > 0" class="flex items-center justify-center px-3 py-1 mt-2 text-white flew-row rounded-xl bg-secondary">
              <i class="pr-0 text-white fas fa-award sm:pr-2" style="font-size: 0.8rem" />
              <span class="hidden text-sm font-base sm:block">Claimed</span>
            </div>
            <div v-else class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border cursor-pointer flew-row rounded-xl hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="openClaimPoemCmeCreditDialog">
              <i class="pr-0 fas fa-award sm:pr-2"></i>
              <span class="hidden text-sm font-base sm:block">Claim Credit</span>
            </div>
          </div>
          <div class="w-full pt-4 mt-4 border-t">
            <Textarea v-model="comment" :autoResize="true" rows="2" cols="30" required maxLength="65535" placeholder="Add a comment. Please do not comment Patient Information" id="commentTextarea" />
            <div class="flex justify-end mt-2">
              <Button label="Comment" icon="pi pi-comment" class="p-button-secondary p-button-outlined" @click="submitComment" :loading="isLoadingComment" :disabled="isLoadingComment" id="commentButton" />
            </div>
          </div>
          <div class="mt-4">
            <div v-if="!hasCompletedAccountSetup" class="relative">
              <div class="absolute left-0 right-0 z-50 m-auto text-center bg-white border border-gray-500 top-4 rounded-xl p-9 w-fit shadow-sm">
                <div class="text-xl font-bold text-secondary">Verify Your Account</div>
                <div class="mt-2 mb-4 text-gray-500">To get full access</div>
                <Button class="p-button-secondary" @click="$router.push('/account-setup')">Verify Account</Button>
              </div>
              <div v-for="comment in 3" :key="comment" class="pt-3 mt-3 border-t">
                <div class="flex flex-row items-center">
                  <div class="relative z-20 object-cover w-10 h-10 bg-gray-100 border-4 border-white border-solid rounded-full" />
                  <span class="w-16 h-5 pl-1 font-semibold bg-gray-100 rounded-md"></span>
                </div>
                <div class="w-full pt-1 pl-10">
                  <div class="w-full h-5 font-semibold bg-gray-100 rounded-md"></div>
                  <div class="w-2/3 h-5 mt-2 font-semibold bg-gray-100 rounded-md"></div>
                </div>
              </div>
            </div>
            <div v-else-if="selectedPoemComments?.length > 0" class="w-full max-w-2xl">
              <div v-for="comment in selectedPoemComments" :key="comment.id" class="pt-3 mt-3 border-t">
                <PoemComment :comment="comment" :poem="selectedPoem" :depth="1" :discussionComment="comment" />
              </div>
            </div>
            <div v-else>
              <h2 class="text-lg font-semibold">Be the first to comment!</h2>
              <div class="mt-1 text-sm">Nobody's responded to this post yet.</div>
              <div class="text-sm">Add your thoughts and get the conversation going.</div>
            </div>
          </div>
        </template>
      </article>
    </div>
    <Head>
      <title>Virtual Hallway Presents - {{ selectedPoem?.title }}</title>
      <meta property="og:title" :content="`Virtual Hallway Presents - ${selectedPoem?.title}`" />
      <meta property="og:description" content="POEMs are daily, expert-reviewed summaries of the latest clinical research delivered to your inbox. Enhance your knowledge and earn credits - all before your next patient." />
      <meta property="og:url" :content="`https://app.virtualhallway.ca/poem/${selectedPoem?.id}`" />
      <meta name="description" content="POEMs are daily, expert-reviewed summaries of the latest clinical research delivered to your inbox. Enhance your knowledge and earn credits - all before your next patient." />
    </Head>
  </div>
  <ClaimPoemCmeCreditDialog ref="claimPoemCmeCreditDialog" />
  <Modal ref="upgradeAccountModal" class="mx-auto">
    <template v-slot:body>
      <div class="w-full px-6 py-8 m">
        <div class="flex flex-col items-center justify-center w-full px-10 py-8">
          <div class="text-xl font-bold text-secondary">Verify Your Account</div>
          <div class="mt-2 mb-8 text-gray-500">To get full access</div>
          <Button class="p-button-secondary" @click="$router.push('/account-setup')">Verify Account</Button>
        </div>
      </div>
    </template>
  </Modal>
  <Menu ref="menu" style="width: 210px" :model="menuItems" :popup="true"> </Menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import Modal from '@/components/misc/Modal.vue';
import RegistrationForm from '@/views/public/registration/registrationForm/RegistrationForm.vue';
import { Share } from '@capacitor/share';
import Menu from 'primevue/menu';
import { Head } from '@unhead/vue/components';

export default {
  inject: ['mixpanel'],
  components: {
    Loading,
    Modal,
    RegistrationForm,
    Menu,
    Head,
  },
  data() {
    return {
      isLoadingComment: false,
      isLoading: false,
      comment: null,
      menuItems: [],
    };
  },
  computed: {
    ...mapGetters(['selectedPoem', 'loggedInUser', 'selectedPoemComments']),
    hasCompletedAccountSetup() {
      return this.loggedInUser?.role && this.loggedInUser?.first_name && this.loggedInUser?.last_name && (this.loggedInUser?.role !== 'cme' || (this.loggedInUser?.profession && this.loggedInUser?.address.province));
    },
  },
  methods: {
    ...mapActions(['getPoem', 'cratePoemComment', 'togglePoemBookmark', 'togglePoemLike', 'createUserAnalytic', 'getPoemComments']),
    submitComment() {
      if (!this.comment) {
        return;
      }

      if (!this.hasCompletedAccountSetup) {
        this.$refs.upgradeAccountModal.openModal();
        return;
      }

      this.isLoadingComment = true;
      this.cratePoemComment({
        poem_id: this.selectedPoem.id,
        text: this.comment,
      })
        .then((data) => {
          this.comment = null;
          this.isLoadingComment = false;
        })
        .catch(() => {
          this.isLoadingComment = false;
          this.showGenericErrorToast();
        });
    },
    togglePoemBookmarkOnSubmit(poem) {
      if (poem.bookmarks.length > 0) {
        poem.bookmarks = [];
      } else {
        poem.bookmarks.push(this.loggedInUser);
      }
      this.togglePoemBookmark({ poem_id: poem.id })
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    togglePoemLikeOnSubmit(poem) {
      if (poem.likes.length > 0) {
        poem.likes = [];
        poem.likes_count--;
      } else {
        poem.likes.push(this.loggedInUser);
        poem.likes_count++;
      }
      this.togglePoemLike({ poem_id: poem.id })
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    openClaimPoemCmeCreditDialog() {
      if (!this.hasCompletedAccountSetup) {
        this.$refs.upgradeAccountModal.openModal();
        return;
      }
      this.$refs.claimPoemCmeCreditDialog.openDialog();
    },
    redirectToLogin() {
      this.$router.push(`/login?redirect=poem/${this.selectedPoem.id}`);
    },
    async shareOnClick(event, poem) {
      let hostname = window.location.hostname;
      if (hostname === 'localhost') {
        hostname = 'localhost:8080';
      }
      const url = `${hostname}/poem/${poem.id}`;

      if (this.isNativePlatform()) {
        try {
          await Share.share({
            title: 'Virtual Hallway POEMs',
            text: poem.title,
            url,
            dialogTitle: 'Share POEMs Link',
          });
        } catch (e) {}
      } else {
        this.setShareLinks(poem);
        this.$refs.menu.toggle(event);
      }
    },
    setShareLinks(poem) {
      let hostname = window.location.hostname;
      if (hostname === 'localhost') {
        hostname = 'localhost:8080';
      }
      const baseUrl = `${hostname}/poem/${poem.id}`;
      this.menuItems = [
        {
          label: 'Facebook',
          icon: 'pi pi-facebook',
          command: () => {
            const url = encodeURI(`https://www.facebook.com/share_channel/?link=${baseUrl}&app_id=966242223397117&source_surface=external_reshare&display&hashtag=#CME`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'LinkedIn',
          icon: 'pi pi-linkedin',
          command: () => {
            const url = encodeURI(`https://www.linkedin.com/sharing/share-offsite/?url=${baseUrl}`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'Twitter',
          icon: 'pi pi-twitter',
          command: () => {
            const url = encodeURI(`https://x.com/intent/post?text=${poem.title}&url=${baseUrl}&hashtags=#CME`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'Copy Link',
          icon: 'pi pi-link',
          command: () => {
            this.copyToClipboard(poem);
          },
        },
      ];
    },
    copyToClipboard(poem) {
      let hostname = window.location.hostname;
      if (hostname === 'localhost') {
        hostname = 'localhost:8080';
      }
      const url = `${hostname}/poem/${poem.id}`;

      navigator.clipboard.writeText(url);
      this.$toast.add({
        severity: 'success',
        summary: 'Link Copied',
        life: 2000,
      });
    },
  },
  created() {
    this.isLoading = true;
    this.getPoem({ id: this.$route.params.id })
      .then(() => {
        this.isLoading = false;
        let data = {
          analyticable_type: 'App\\Models\\Poem',
          analyticable_id: this.selectedPoem.id,
          description: 'User viewed poem',
          tags: ['Poems'],
          meta_data: JSON.stringify({ url_query: this.$route.fullPath }),
        };
        this.createUserAnalytic(data)
          .then(() => {})
          .catch(() => {});
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });
    if (this.loggedInUser) {
      this.isLoadingComments = true;
      this.getPoemComments({ poem_id: this.$route.params.id })
        .then(() => {
          this.isLoadingComments = false;
        })
        .catch(() => {
          this.isLoadingComments = false;
        });
    }
  },
  watch: {
    loggedInUser(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.getPoem({ id: this.$route.params.id })
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
  },
};
</script>

<style>
.poem-container,
.poem-container * {
  display: block;
  box-sizing: border-box;
}
.poem-container {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #1f2937;
  position: relative;
  display: block;
  text-wrap: pretty;
}
.poem-container b {
  padding-top: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: black;
}
.poem-container b ~ br {
  display: none;
}
.poem-container .poem_author {
  padding-top: 0.75rem;
}
.poem-container a {
  display: inline-block;
}
</style>
