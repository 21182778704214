<template>
  <h1 class="mb-3 text-xl font-bold">Subscribe To Receive POEMs</h1>
  <form method="post" @submit.prevent="onSubmit" class="w-full">
    <div class="py-2" v-if="!loggedInUser">
      <span class="p-float-label">
        <label for="subscribeToPoemsEmail">Email </label>
        <InputText type="email" name="subscribeToPoemsEmail" id="subscribeToPoemsEmail" v-model="email" required :class="['w-full']" maxLength="255" />
      </span>
    </div>
    <div v-else>
      <p class="w-full text-sm text-gray-700">Receive expert-reviewed summaries of the latest clinical research delivered to your inbox.</p>
    </div>

    <div class="mt-4">
      <Button type="submit" id="subscribeToPoemsSubmitButton" class="w-full text-center p-button-secondary" label="Subscribe"></Button>
    </div>
    <div class="mt-6 text-sm text-center font-display" v-if="!loggedInUser">Already subscribed? <span class="cursor-pointer text-primary" @click="redirectToLogin">Log in here</span>&nbsp;</div>
  </form>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import { mapGetters, mapActions } from 'vuex';
export default {
  props: ['selectedPoem'],
  emits: ['successfullySubscribedToPoems'],
  inject: ['mixpanel'],
  components: { Button, InputText },
  data() {
    return {
      email: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['subscribeToKlaviyoList', 'updateNotificationSetting']),
    onSubmit() {
      const promises = [];
      if (this.loggedInUser) {
        let notificationSetting = this.loggedInUser.profile.notification_setting;
        Object.keys(notificationSetting).forEach((key) => {
          if (!Array.isArray(notificationSetting[key])) {
            notificationSetting[key] = JSON.parse(notificationSetting[key]);
          }
        });
        notificationSetting.poems = ['Email'];
        promises.push(this.updateNotificationSetting(notificationSetting));
      } else {
        promises.push(this.subscribeToKlaviyoList({ email: this.email, list: 'Poems' }));
      }

      Promise.all(promises)
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Subscribed To Poems',
            detail: 'You will now receive poems via email.',
            life: 5000,
          });
          this.$emit('successfullySubscribedToPoems', { prefillEmail: this.email });
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    redirectToLogin() {
      if (this.selectedPoem) {
        this.$router.push(`/login?redirect=poem/${this.selectedPoem.id}`);
      } else {
        this.$router.push(`/login?redirect=poems`);
      }
    },
  },
  created() {
    if (this.loggedInUser) {
      this.email = this.loggedInUser.email;
    }
  },
};
</script>

<style></style>
