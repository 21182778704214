<template>
  <div class="grid lg:grid-cols-1 xl:grid-cols-2" :class="isNativePlatform() ? 'bg-white !h-screen' : ''">
    <div v-if="!isNativePlatform()" class="flex-row items-center justify-center hidden py-12 bg-white xl:flex">
      <img src="@/assets/App-Screenshot.webp" alt="" class="w-full mr-6" style="max-width: 250px" />
      <div class="flex flex-col items-start p-2 text-left bg-white rounded" style="max-width: 360px">
        <i class="mb-4 pi pi-mobile text-primary" style="font-size: 3rem" />
        <h1 class="mb-4 text-3xl font-semibold">Take Virtual Hallway Wherever You Go</h1>
        <p class="mb-4 text-xs">Experience the convenience of Virtual Hallway on your mobile iOS device. Secure consults, seamless documentation, and private calling - all in your pocket. Enter your cell number to get the app link via text.</p>
        <TwilioAppLinkForm />
        <div class="flex flex-col justify-center">
          <div class="flex flex-row items-center my-4">
            <div class="flex-grow bg-gray-300" style="height: 1px"></div>
            <p class="px-2 text-xs text-gray-500 font-display">Or</p>
            <div class="flex-grow bg-gray-300" style="height: 1px"></div>
          </div>
          <a href="https://virtualhallway.ca/appstore"><img src="@/assets/App-Store-Button.png" alt="" class="w-full" style="max-width: 140px" /></a>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center w-full" :class="isNativePlatform() ? 'h-full py-12' : ' py-6'">
      <div v-if="toggleTransition" :class="isNativePlatform() ? 'w-full border-4 border-solid border-white p-6 rounded bg-white mt-12' : 'items-start text-left mx-auto my-9 max-w-sm   w-full  p-8 tile'">
        <div class="flex flex-col items-center justify-center w-full text-center">
          <template v-if="isNativePlatform()">
            <img src="@/assets/icon.png" class="mx-auto mb-6" style="max-width: 35%" />
            <h2 class="mb-4 text-sm font-bold">Welcome Back to Virtual Hallway</h2>
          </template>
          <h2 class="mb-2 text-xl font-bold">Sign In to Your Account</h2>
          <!-- Wording change for liveupdate testing -->
          <div class="mb-2 text-xs font-medium text-gray-500 font-display">Not yet registered? <span class="cursor-pointer text-primary" @click="$router.push('/registration')">Click here</span>&nbsp;to get started.</div>
          <div class="mt-2 mb-1" v-if="$route.params?.type == 'cortico_referral_request'">
            <InlineMessage severity="info">Please sign into your account to make the consult request</InlineMessage>
          </div>
        </div>
        <LoginForm />
      </div>
      <div class="flex flex-row justify-center mb-10 text-xs text-center">
        <div class="px-5 border-r border-black"><EulaDialog /></div>
        <div class="px-5 border-r border-black"><GeneralPrivacyPolicyDialog /></div>
        <div class="px-5"><PlatformPrivacyPolicyDialog /></div>
      </div>
    </div>
  </div>

  <iosAppDownloadPromptModal v-if="isIphoneBrowserButNotIosApp()" />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Loading from 'vue-loading-overlay';
import Button from 'primevue/button';
import InlineMessage from 'primevue/inlinemessage';
import LoginForm from './LoginForm.vue';
import TwilioAppLinkForm from '../user/TwilioAppLinkForm.vue';
import EulaDialog from '@/components/misc/EulaDialog.vue';
import GeneralPrivacyPolicyDialog from '@/components/misc/GeneralPrivacyPolicyDialog.vue';
import PlatformPrivacyPolicyDialog from '@/components/misc/PlatformPrivacyPolicyDialog.vue';

export default {
  inject: ['mixpanel'],
  data() {
    return {
      toggleTransition: false,
    };
  },
  components: {
    Loading,
    Button,
    InlineMessage,
    LoginForm,
    TwilioAppLinkForm,
    GeneralPrivacyPolicyDialog,
    PlatformPrivacyPolicyDialog,
  },
  computed: {
    ...mapGetters(['lastLoggedInUserEmail']),
  },
  created() {
    if (this.isIphoneBrowserButNotIosApp()) {
      // If the app is not installed the script will wait for 2sec and redirect to web.
      const loginComponentCreatedFunc = this.loginComponentCreated;
      setTimeout(function () {
        loginComponentCreatedFunc();
        // window.location.href = 'https://virtualhallway.ca/appstore';
      }, 4000);

      window.location.href = `virtualhallway:/${this.$route.fullPath}`;
    } else {
      this.loginComponentCreated();
    }
  },
  mounted() {
    this.toggleTransition = true;
  },
  methods: {
    // If auto logout modal was closed, we want to get rid of all params associated with
    // it to prevent the original login behaving like an autologout login.
    autoLogoutLoginDialogClosed() {
      this.$route.query = {};
    },
    loginComponentCreated() {
      if (this.$route.query.redirect) {
        let redirectRoute = this.$route.query.redirect;
        if (redirectRoute.includes('permission/allowtobeadded')) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Account Login',
            detail: 'Please login to your account to process this request.',
            life: 5000,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.login-form-heading-icon {
  color: var(-vh-bright-light-blue);
  font-size: 3rem;
}

.input-field a {
  display: block;
  color: #a2a2a2;
  text-align: center;
  font-size: 18px;
  padding-bottom: 0px;
}
.input-field label {
  line-height: 1.3;
  font-size: 14px;
  padding-left: 5px;
  /* padding-left: 15px; */
  color: var(--vh-label-gray);
}

.slide-up-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-up-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-up-fade-enter-from,
.slide-up-fade-leave-to {
  transform: translatey(50px);
  opacity: 0;
}
</style>
