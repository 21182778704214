<template>
  <div v-if="referrals.length < 1" class="flex flex-col items-center justify-center w-full p-6 mb-4 no-consult-card">
    <i class="mb-3 text-gray-500 pi pi-info-circle" style="font-size: 2rem"></i>
    <h3 class="font-semibold text-center text-gray-500" v-if="label == 'Upcoming'">No {{ this.label }} Consults</h3>
    <h3 class="font-semibold text-center text-gray-500" v-else-if="label == 'Recent'">No consults found in last 2 weeks.</h3>
  </div>
  <div v-else-if="label == 'Recent'" class="my-3 text-sm font-semibold text-center">Consults within Last 2 Weeks</div>
  <template v-if="referrals.length > 0">
    <div v-for="(referral, index) in referrals" :key="referral.id" @click="selectReferral(referral.id)" class="relative w-full p-3 mb-3 text-left cursor-pointer tile animation-delay-stagger animated fade-in-up hover:bg-gray-50 border-1" :style="'--index: ' + index">
      <div class="flex flex-row justify-between mb-1">
        <template v-if="loggedInUser.id === referral.referee_id || actingUser?.id === referral.referee_id">
          <div class="text-left">
            <h1 class="text-sm font-bold text-gray-600">{{ referral.referral_person_full_name }}</h1>
            <span class="text-xs">{{ referral.specialty?.name }} - </span>
            <span :class="'text-xs font-bold text-status-' + referral.className">
              {{ referral.status }}
            </span>
          </div>
        </template>
        <template v-else>
          <div class="text-left">
            <h1 class="text-sm font-bold text-gray-600">{{ referral.referee_full_name }}</h1>
            <span class="text-xs text-gray-600">Status: </span>
            <span :class="'text-xs font-bold text-status-' + referral.className">
              {{ referral.status }}
            </span>
            <span v-if="referral?.interaction_type?.name === 'phone' && referral.call_asap == true && referral?.status != 'Complete'" class="text-xs font-bold font-display"> - <i class="pi pi-bolt" style="font-size: 0.8rem"></i> On Demand</span>
          </div>
        </template>
      </div>

      <div class="flex flex-col justify-between w-full">
        <div class="flex flex-row my-1" v-if="referral.referral_form.patient_first_name">
          <div class="flex items-center justify-center mr-2 rounded align-center border-1" style="width: 2rem; height: 2rem">
            <i class="text-xl text-left text-gray-400 pi pi-user"></i>
          </div>
          <div class="flex flex-col items-start py-0 mb-1">
            <div class="text-xs font-normal text-gray-400">Patient</div>
            <div class="text-xs font-semibold text-gray-600">{{ referral.referral_form.patient_first_name }} {{ referral.referral_form.patient_last_name }}</div>
          </div>
        </div>
        <template v-if="referral?.interaction_type?.name === 'phone'">
          <div class="flex flex-row">
            <div class="flex items-center justify-center mr-2 rounded align-center border-1" style="width: 2rem; height: 2rem">
              <i class="text-xl text-left text-gray-400 pi pi-calendar"></i>
            </div>
            <div class="flex flex-col items-start py-0">
              <div class="text-xs font-normal text-gray-400">Date</div>
              <div class="text-xs font-semibold text-gray-600">{{ formatReadableDateTime(referral.start_datetime) }}</div>
            </div>
          </div>
        </template>
        <template v-if="referral?.interaction_type?.name === 'econsult'">
          <div class="flex flex-row my-1">
            <div class="flex items-center justify-center mr-2 rounded align-center border-1" style="width: 2rem; height: 2rem">
              <i class="text-xl text-left text-gray-400 pi pi-envelope"></i>
            </div>
            <div class="flex flex-col items-start py-0">
              <div class="text-xs font-normal text-gray-400 uppercase">Consult Type</div>
              <div class="flex flex-row text-xs font-semibold text-gray-500">
                {{ referral?.interaction_type?.display_name }} <span class="flex"><i class="flex text-pink-500 fa fa-plus" style="font-size: 0.7rem; font-weight: 900"></i>&nbsp;</span>
              </div>
            </div>
          </div>
        </template>
        <div v-if="referral.status == 'Pending' && isGP(actingUser)" class="mt-2">
          <Button label="Edit/Submit" class="w-full p-button-xs p-button-secondary" icon="pi pi-pencil"></Button>
        </div>
        <div v-if="referral.status == 'Cancelled' && isGP(actingUser)" class="mt-2">
          <Button label="Reschedule" class="w-full p-button-xs p-button-secondary" icon="pi pi-refresh" v-on:click.stop @click="rebookReferral(referral.id)"></Button>
        </div>
        <a v-if="referral.status == 'In Review' || referral.status == 'Upcoming' || (referral?.interaction_type?.name === 'phone' && referral.call_asap == true && referral.status != 'Complete' && referral.referee?.phone_number != null)" v-on:click.stop :href="`tel:${referral.referee?.phone_number}`" class="absolute flex md:hidden bottom-2 right-2">
          <Button v-if="isSpecialist(loggedInUser)" class="items-center w-full p-button-sm p-button-rounded p-button-secondary" icon="pi pi-phone" />
        </a>
      </div>
    </div>
  </template>
</template>
<script>
import Button from 'primevue/button';
import { mapGetters } from 'vuex';

export default {
  props: ['referrals', 'label'],
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'actingUser', 'isSpecialist']),
  },
  components: {
    Button,
  },
  methods: {
    selectReferral(id) {
      this.$router.push({ path: '/referral/' + id });
    },
    rebookReferral(id) {
      this.$router.push({ path: '/referral/' + id, query: { rebook: 'yes' } });
    },
  },
};
</script>

<style>
.no-consult-card {
  height: 245px;
}

.rotate-45 {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}
</style>
