<template>
  <div class="flex flex-col items-center justify-center w-full" v-if="!isLoading">
    <section class="flex flex-col items-center w-full px-4 py-24 bg-secondary" :class="{ '-mt-10': !isLoggedIn }">
      <div class="flex flex-row justify-start w-full px-2 mb-4 text-sm font-semibold text-white cursor-pointer md:hidden align-start" :class="{ 'mt-12': isNativePlatform() }">
        <h1 @click="$router.push('/lectures')" id="singleLectureBackButton">&lt; <span class="border-b-2 border-white border-solid">Back</span></h1>
      </div>
      <div id="lecture_header" class="relative w-full max-w-5xl p-6 mt-4 text-left tile animated fade-in-up rounded-xl md:p-14">
        <div class="absolute top-5 right-5">
          <SocialShareButtons :selectedLecture="this.selectedLecture" />
        </div>
        <!-- <i v-else @click="toggleLectureBookmarkOnClick()" class="absolute mr-2 text-gray-600 cursor-pointer pi top-5 right-3 hover:text-gray-800" :class="[{ 'pi-bookmark': this.bookmarked == false }, { 'pi-bookmark-fill': this.bookmarked == true }]" style="font-size: 1.6rem" v-tooltip.left="'Add/remove from your bookmarks'"></i> -->

        <div class="flex flex-col w-full">
          <div class="w-full">
            <div id="lecture_info_wrapper" class="w-full">
              <h4 class="mb-3 text-xs font-medium tracking-widest text-gray-400">VIRTUAL HALLWAY PRESENTS</h4>
              <h1 class="mb-3 text-xl font-bold text-gray-700 md:text-3xl">{{ selectedLecture.lecture_title }}</h1>

              <div v-if="isCurrentLecture" class="flex flex-row mb-4">
                <div class="relative mr-4">
                  <div class="text-red-400 pulsing-circle"></div>
                </div>
                <h2 class="font-bold">Presenting Live Now</h2>
              </div>
              <h2 v-else class="mb-3 font-bold"><span v-if="!isUpcomingLecture">Aired </span>{{ formatReadableDateTimeWithZone(selectedLecture.lecture_datetime) }}</h2>

              <div v-if="!isUpcomingLecture && this.loggedInUser && this.total_views > 20" class="flex flex-row items-center mb-4 text-sm font-display md:text-md">
                <i class="mr-2 pi pi-users" style="font-size: 1.3rem"></i>
                <span>{{ this.total_views }} clinicians have watched this lecture</span>
              </div>
              <div v-if="isUpcomingLecture && this.loggedInUser" class="flex flex-row items-center mb-4 text-sm font-display md:text-md">
                <i class="mr-2 pi pi-users" style="font-size: 1.3rem"></i>
                <Skeleton v-if="loadingRegistrants" height="1.4rem" width="12rem"></Skeleton>
                <span v-else>{{ this.selectedLecture.total_registrants + 12 }} clinicians attending</span>
              </div>

              <!-- TODO Add CLS fix -->
              <div id="lecture_video" v-if="selectedLecture.video_upload_url" ref="videoPlayer" class="relative flex flex-col justify-center w-full max-w-5xl mb-4">
                <vimeo-player ref="player" :video-url="selectedLecture.video_upload_url" :options="{ responsive: true }" @ready="onReady" @pause="onPause" @ended="onEnded" @play="onPlay" />
                <div v-if="!this.loggedInUser" @click="showLoginModal()" class="flex flex-col items-center justify-center w-full max-w-5xl mb-10 cursor-pointer transition duration-300 ease-out video-player-branded-overlay">
                  <img src="@/assets/Virtual-Hallway-Logo-Nav.png" class="mb-2 video-logo" />
                  <i class="mb-4 text-white transition duration-300 ease-in-out fas fa-play-circle hover:text-gray-400 video-play-icon"></i>
                  <h3 class="font-medium text-white">Click to Play</h3>
                </div>
                <div v-else-if="this.loggedInUser && !overlayHidden" @click="playAndHideOverlay()" class="flex flex-col items-center justify-center w-full max-w-5xl mb-10 cursor-pointer transition duration-300 ease-out video-player-branded-overlay">
                  <img src="@/assets/Virtual-Hallway-Logo-Nav.png" class="mb-2 video-logo" />
                  <i class="mb-4 text-white transition duration-300 ease-in-out fas fa-play-circle hover:text-gray-400 video-play-icon"></i>
                  <h3 class="font-medium text-white">Click to Play</h3>
                </div>
              </div>

              <pre class="text-sm text-gray-600 md:text-md">{{ selectedLecture.lecture_description }}</pre>
              <p v-if="this.showArchiveDate" class="mt-5 mb-6 text-sm font-semibold text-red-400">Please Note: The expiration date to earn CME credits from this lecture is {{ this.archiveDate }}</p>
              <div id="lecture_sponsor_name" v-if="selectedLecture.lecture_sponsor_name" class="w-full max-w-5xl mt-4 text-left">
                <p class="text-xs italic text-gray-600 md:text-sm">This lecture is sponsored by a {{ selectedLecture.lecture_sponsor_name }} educational grant.</p>
              </div>
            </div>
            <div v-if="loggedInUser" class="flex flex-col mt-4 gap-4 md:flex-row">
              <template v-if="this.zoom_url && !this.join_url">
                <Button v-if="isCurrentLecture" label="Join Now" class="w-full md:w-56 p-button" @click="showZoomLectureRegistrationClick()" />
                <Button v-else-if="isUpcomingLecture" label="Register" class="w-full md:w-56 p-button" @click="showZoomLectureRegistrationClick()" />
              </template>
              <template v-if="this.join_url">
                <Button v-if="isCurrentLecture" label="Join Now" class="w-full md:w-56 p-button" @click="openExternalZoomWebinarLink()" />
                <Button v-else-if="isUpcomingLecture" label="Registered" icon="pi pi-check-square" class="w-full md:w-56 p-button p-button-success" @click="openExternalZoomWebinarLink()" />
              </template>
              <Button v-if="cmeFormAvailable" label="Claim CME Credit" class="w-full md:w-56 p-button" icon="pi fas fa-award" @click="claimCMECreditOnClick()" />
            </div>
            <div v-else class="flex flex-col gap-4 md:flex-row">
              <!-- <Button v-if="this.zoom_url && isUpcomingLecture" label="Register" class="w-full md:w-56 p-button" icon="pi pi-video" @click="redirectToRegistrationWithLectureId()" /> -->
              <Button v-if="this.video_upload_url" label="Watch Lecture" class="w-full mt-4 md:w-56 p-button" icon="pi pi-video" @click="showLoginModal()" />
              <Button v-if="cmeFormAvailable" label="Claim CME Credit" class="w-full mt-4 md:w-56 p-button" icon="pi fas fa-award" @click="showLoginModal()" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="lecture_info_section" class="flex flex-col items-center w-full px-4 bg-white">
      <div id="lecture_registration" v-if="isUpcomingLecture && !this.loggedInUser" class="w-full max-w-5xl p-6 text-left tile -mt-14 rounded-xl md:p-14 border-1">
        <div class="flex flex-row">
          <div class="w-full">
            <RegistrationForm :background_role="'cme'" :loggedInUser="loggedInUser" :lecture_id="this.selectedLecture.id" :customHeadline="'Lecture Registration'" :customButtonLabel="'Register/Sign In to Attend'" :queryProps="{ lecture_id: lecture_id, affiliate_id: affiliate_id, showZoomLectureRegistrationDialog: true }" />
          </div>
          <div class="flex-col justify-between flex-grow hidden w-full pl-16 text-sm md:flex gap-y-10">
            <div class="flex flex-col justify-center rounded">
              <div style="min-width: 4rem"><i class="mb-2 mr-5 text-primary fas fa-stethoscope" style="font-size: 2rem"></i></div>
              <div class="flex flex-col">
                <h1 class="text-lg font-semibold">How do I attend the lecture?</h1>
                <h2>After registration, you will receive an email link with the details on how to join from your device.</h2>
              </div>
            </div>
            <div class="flex flex-col justify-center rounded">
              <div style="min-width: 4rem"><i class="mb-2 mr-5 text-primary fas fa-award" style="font-size: 2rem"></i></div>
              <div class="flex flex-col">
                <h1 class="text-lg font-semibold">How do I get credited?</h1>
                <h1 v-if="selectedLecture.cme_credit_available > 0">{{ selectedLecture.cme_credit_amount }} Mainpro + Credit(s) will be emailed to you after the lecture.</h1>
                <h1 v-else-if="selectedLecture.cme_credit_available">None</h1>
              </div>
            </div>
            <div class="flex flex-col justify-center rounded">
              <div style="min-width: 4rem"><i class="mb-2 mr-5 text-primary fas fa-hourglass" style="font-size: 2rem"></i></div>
              <div class="flex flex-col">
                <h1 class="text-lg font-semibold">What if I miss the lecture?</h1>
                <h1>If you are unable to make it to the live webinar, you can always watch the recording on your own time later.</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isLoggedIn" id="lecture_details" class="w-full max-w-5xl p-6 mb-10 text-left tile -mt-14 rounded-xl md:p-6 md:px-14 border-1">
        <div class="flex flex-col items-start w-full gap-4 md:flex-row md:justify-between">
          <div v-if="!isArchivedLecture && selectedLecture.cme_credit_available > 0" class="flex flex-row justify-center">
            <div style="min-width: 4rem"><i class="mt-2 mb-2 mr-5 fas fa-award" style="font-size: 2.5rem"></i></div>
            <div class="flex flex-col">
              <h1 class="font-semibold">CME Credits</h1>
              <h1>{{ selectedLecture.cme_credit_amount }} Mainpro +</h1>
            </div>
          </div>
          <div class="flex flex-row justify-center">
            <div style="min-width: 4rem"><i class="mt-2 mb-2 mr-5 fas fa-stethoscope" style="font-size: 2.5rem"></i></div>
            <div class="flex flex-col">
              <h1 class="font-semibold">Specialty</h1>
              <h2>{{ selectedLecture.presenter_specialty }}</h2>
            </div>
          </div>
          <div class="flex flex-row justify-center">
            <div style="min-width: 4rem"><i class="mt-2 mb-2 mr-5 fas fa-hourglass" style="font-size: 2.5rem"></i></div>
            <div class="flex flex-col">
              <h1 class="font-semibold">Duration</h1>
              <h1>{{ selectedLecture.lecture_duration }} Hour</h1>
            </div>
          </div>
        </div>
      </div>

      <div id="learning_objectives" v-if="selectedLecture.learning_objectives" class="w-full max-w-5xl px-2 mb-10 text-left md:px-14" :class="{ 'mt-10': !isLoggedIn }">
        <h3 class="mb-2 text-xl font-bold">Learning Objectives</h3>
        <pre class="text-xs text-gray-600 md:text-sm">{{ selectedLecture.learning_objectives }}</pre>
      </div>
      <ThatOtherLecture v-if="selectedLecture.lecture_title == 'The Basal Blueprint: Insulin Management In Primary Care'" />
      <div v-else id="presenter_info_wrapper" class="w-full max-w-5xl p-6 mb-10 text-left tile border-1" :class="{ 'mt-10': !isLoggedIn }">
        <div class="flex flex-col md:flex-row">
          <img v-if="selectedLecture.presenter_image_location" :src="selectedLecture.presenter_image_location" class="object-cover w-24 h-24 mb-4 mr-6 rounded-xl md:mb-0" />
          <div class="flex flex-col mb-4 mr-8 md:mb-0">
            <h2 class="mb-2 text-xl font-bold">Speaker</h2>
            <div class="flex flex-row items-center font-display">
              <p class="mb-2 mr-2 text-sm whitespace-nowrap">Dr. {{ selectedLecture.presenter_name }}</p>
            </div>
            <p class="text-sm font-display">{{ selectedLecture.presenter_specialty }}</p>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-row items-center">
              <p class="mr-2 text-sm">{{ selectedLecture.presenter_bio }}</p>
            </div>
          </div>
        </div>
      </div>

      <div id="lecture_resources_downloads" v-if="selectedLectureMaterialFiles.length > 0" class="w-full max-w-5xl px-2 mb-10 text-left md:px-14">
        <h3 class="mb-4 text-xl font-bold">Lecture Resources and Downloads</h3>
        <div class="flex flex-row items-center justify-between p-2 px-4 border-b border-gray-100 border-solid rounded cursor-pointer last:border-none hover:bg-gray-100" @click="clickDownloadFile(file.id)" v-for="(file, index) in selectedLectureMaterialFiles" :key="file.id">
          <div class="flex flex-row items-center justify-start text-start">
            <i class="mr-2 pi pi-file"></i>
            <div class="text-sm font-semibold text-gray-600 cursor-pointer font-display">{{ file.original_file_name }}</div>
          </div>
          <div>
            <Button icon="pi pi-download" class="p-button-rounded p-button-secondary p-button-text" @click="clickDownloadFile(file.id)" v-tooltip.bottom="'Click to download this file.'" :id="'downloadSelectedFileButton-' + index" />
          </div>
        </div>
      </div>

      <div id="claim_credit_cta" v-if="cmeFormAvailable" class="w-full max-w-5xl p-6 text-left shadow mb-14 rounded-xl border-1">
        <div class="flex flex-col items-center justify-between gap-4 md:flex-row">
          <div class="text-md md:text-xl">
            <h2 class="mb-2 text-lg font-bold md:text-2xl md:mb-0">Ready to claim your credit?</h2>
            <p class="pr-0 text-xs md:pr-8 md:text-sm">This group learning has been certified by the College of Family Physicians of Canada and awards 1 Mainpro+ certified credit. After submitting feedback your certificate will be emailed to you.</p>
          </div>
          <div v-if="cmeFormAvailable && !isArchivedLecture"><Button label="Claim CME Credit" class="w-56 p-button" icon="pi fas fa-award" @click="claimCMECreditOnClick" /></div>
        </div>
      </div>
    </section>

    <RelatedLectures :pastLectures="pastLectures" />
  </div>
  <section class="flex flex-col justify-center h-screen p-5" v-else>
    <ProgressSpinner />
  </section>

  <Modal ref="loginModal" class="mx-auto" v-if="!loggedInUser">
    <template v-slot:body>
      <div class="flex flex-col items-center justify-center w-full max-w-xl px-6 py-8 rounded-b-xl">
        <div class="flex flex-col w-full max-w-lg px-2 text-center text-gray-600 text-md">
          <RegistrationForm :background_role="'cme'" :loggedInUser="loggedInUser" :customHeadline="'Sign up to watch'" :queryProps="{ lecture_id: lecture_id, affiliate_id: affiliate_id }" />
          <div class="mt-4 text-sm font-bold font-display">Already have an account? <span class="cursor-pointer text-primary" @click="redirectToLoginWithLectureId()">Log in here</span>&nbsp;</div>
        </div>
      </div>
    </template>
  </Modal>

  <Dialog header="Claim your CME Credit" :draggable="false" v-model:visible="displayFormDialog" :dismissableMask="true" :modal="true" :breakpoints="{ '900px': '75vw', '640px': '90vw' }" style="max-width: 800px">
    <div v-if="cmeFormAvailable" id="feedback-form-container" class="w-full" ref="feedbackForm">
      <form v-show="lectureCreditFormSubmitted != true" @submit.prevent="submit" class="-mt-6 border-2 border-white border-solid rounded-lg">
        <Message severity="info" :closable="false" class="text-left">Please complete the feedback form below by rating each question between 1-Strongly Disagree and 5-Strongly Agree. After clicking "Submit Feedback", we will email you documentation that you can submit for CME accreditation.</Message>
        <div class="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2">
          <div class="flex flex-col mb-2">
            <p class="mb-3 text-xs">Did the session/program meet the stated objectives?</p>
            <Rating v-model="starValue1" :modelValue="5" :cancel="false" />
          </div>
          <div class="flex flex-col mb-2">
            <p class="mb-3 text-xs">Was the program relevant to your practice (family medicine)?</p>
            <Rating v-model="starValue2" :modelValue="5" :cancel="false" />
          </div>
          <div class="flex flex-col mb-2">
            <p class="mb-3 text-xs">Did the content meet your expectations?</p>
            <Rating v-model="starValue3" :modelValue="5" :cancel="false" />
          </div>
          <div class="flex flex-col mb-2">
            <p class="mb-3 text-xs">Were there adequate opportunities to interact with peers?</p>
            <Rating v-model="starValue4" :modelValue="5" :cancel="false" />
          </div>
          <div class="flex flex-col mb-2">
            <p class="mb-3 text-xs">Will the information learned be useful in your practice? If so, how?</p>
            <Rating v-model="starValue5" :modelValue="5" :cancel="false" />
          </div>
          <div class="flex flex-col mb-2">
            <p class="mb-3 text-xs">Did you perceive any degree of bias in any part of the program?</p>
            <div class="flex flex-row text-xs">
              <div class="flex align-items-center">
                <RadioButton v-model="selectedOption" inputId="radio1" ariaLabel="No" name="No" value="No" />
                <label for="radio1" class="ml-2">No</label>
              </div>
              <div class="flex ml-4 align-items-center">
                <RadioButton v-model="selectedOption" inputId="radio2" ariaLabel="Yes" name="Yes" value="Yes" />
                <label for="radio2" class="ml-2">Yes</label>
              </div>
            </div>
          </div>
        </div>
        <div v-auto-animate v-if="selectedOption" class="flex flex-row w-full">
          <TextArea v-if="selectedOption == 'Yes'" v-model="reason_for_bias" class="auth-input" style="margin-top: 1rem; margin-bottom: 1rem" :autoResize="true" rows="5" cols="30" required maxLength="65535" placeholder="Please explain the biases you experienced" />
        </div>
        <div class="flex flex-row justify-end mb-mobile-inset"><Button type="submit" label="Submit Feedback" icon="pi pi-check" class="p-button" @click="submitFeedback" /></div>
      </form>
      <div v-show="lectureCreditFormSubmitted == true" class="flex flex-col items-center justify-center px-16 py-24 border-2 border-gray-200 border-solid rounded-lg">
        <i class="mt-2 mb-6 fas fa-award text-blue" style="font-size: 2.5rem"></i>
        <h2 class="mb-6 text-2xl font-bold text-blue">CME Credit Sent</h2>
        <p class="mb-8 text-sm text-center">
          Your credit certification has been emailed to <strong>{{ loggedInUser.email }}</strong>
        </p>
        <div class="grid grid-cols-1">
          <Button label="More CME Lectures" class="p-button-secondary" @click="$router.push('/lectures')" />
        </div>
      </div>
    </div>
  </Dialog>

  <Dialog header="Lecture Registration" :draggable="false" v-model:visible="showZoomLectureRegistrationFormDialog" :dismissableMask="false" :modal="true" :breakpoints="{ '900px': '75vw', '640px': '90vw' }" class="w-fulls" style="max-width: 600px" :closable="false" id="lectureRegistrationDialog">
    <div id="registration-form-container" class="relative w-full" ref="registrationForm">
      <form @submit.prevent="submitZoomLectureRegistrationClick()" class="px-2 mt-2 mb-2 p-inputtext-sm">
        <div class="grid grid-cols-2">
          <div class="col-span-2">
            <label for="question_for_speaker" class="auth-input">Have a question for the speaker? Submit it below (Optional) </label>
            <TextArea v-model="question_for_speaker" class="w-full" name="question_for_speaker" :autoResize="true" rows="3" cols="30" maxLength="65535" />
          </div>
          <div class="col-span-2">
            <Message severity="info" :closable="false">You will receive the email invitation for the webinar once you confirm registration.</Message>
          </div>
          <div class="flex flex-row justify-end w-full col-span-2 mb-mobile-inset">
            <Button type="submit" label="Confirm Registration" class="p-button" id="confirmLectureRegistrationButton" />
          </div>
        </div>
      </form>
      <div v-if="showLectureRegistrationLoading" class="absolute top-0 left-0 flex flex-row items-center justify-center w-full h-full text-gray-600 bg-white">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>
      <div v-if="showLectureRegistrationSuccess" class="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full bg-white">
        <div class="flex flex-col items-center justify-center grow">
          <div class="flex items-center justify-center w-16 h-16 mb-4 text-green-400 border-2 border-green-400 rounded-full animated fade-in-up">
            <i class="pi pi-check" style="font-size: 2rem"></i>
          </div>
          <h1 class="mb-2 text-lg font-semibold">Successfully Registered</h1>
          <div class="mb-4 text-left text-md">{{ formatReadableDateTimeWithZone(selectedLecture.lecture_datetime) }}</div>
          <div class="px-6 mb-2 text-sm text-center">Please check your email for instructions on how to join, or click the button below to open Zoom.</div>
        </div>
        <div class="flex flex-row justify-end w-full mt-auto mb-mobile-inset gap-2">
          <Button label="Close" class="p-button-secondary" @click="this.showZoomLectureRegistrationFormDialog = !this.showZoomLectureRegistrationFormDialog" id="closeZoomRegistrationButton" />
          <Button v-if="isCurrentLecture" label="Open Zoom" class="p-button-primary" iconPos="right" icon="pi pi-external-link" @click="openExternalZoomWebinarLink()" />
        </div>
      </div>
    </div>
  </Dialog>

  <Dialog header="Finish Setting Up Your Account" @hide="preboardingDialogClosed()" :draggable="false" v-model:visible="displayAccountSetupDialog" :dismissableMask="false" :modal="true" :closable="!$route.query?.showZoomLectureRegistrationDialog">
    <div class="flex flex-col items-center w-full p-3">
      <Message :closable="false" severity="info">To register for the lecture finish setting up your account.</Message>
      <AccountSetupPage :isDialog="true" @finishedPreboarding="userFinishedPreboarding()" />
    </div>
  </Dialog>

  <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" style="max-width: 470px" :draggable="false" group="templating">
    <template #message="slotProps">
      <div class="flex flex-col items-center justify-center w-full py-3">
        <div class="flex items-center justify-center w-24 h-24 mb-4 border-4 border-green-400 rounded-full">
          <i :class="slotProps.message.icon" style="font-size: 2.5rem" class="text-green-400"></i>
        </div>
        <p class="pl-2">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmDialog>
  <Head>
    <title>{{ lecture_title }}</title>
    <meta name="description" :content="'A free CME-accredited ' + this.selectedLecture.presenter_specialty + 'lecture by Dr. ' + this.selectedLecture.presenter_name" />
  </Head>
</template>

<script>
import RegistrationForm from '@/views/public/registration/registrationForm/RegistrationForm.vue';
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Rating from 'primevue/rating';
import Message from 'primevue/message';
import Modal from '@/components/misc/Modal.vue';
import ThatOtherLecture from '@/components/cme/ThatOtherLecture.vue';
import SocialShareButtons from '@/components/cme/SocialShareButtons.vue';
import AccountSetupPage from '@/views/user/onboarding/AccountSetupPage.vue';
import moment from 'moment';
import { Head } from '@unhead/vue/components';

import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  inject: ['mixpanel'],
  components: { SocialShareButtons, SelectButton, Button, Dialog, InputText, TextArea, Rating, Message, Modal, Head, ProgressSpinner, ThatOtherLecture, RegistrationForm, AccountSetupPage },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        is_in_email_list: true,
        password: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        background_role: 'cme',
        lecture_id: '',
        affiliate_id: null,
        province: '',
        postal_code: '',
        profession: '',
      },
      join_url: '',
      bookmarked: false,
      user: null,
      showZoomLectureRegistrationFormDialog: false,
      zoomLectureRegistrationFormSubmitted: false,
      showLectureRegistrationLoading: false,
      showLectureRegistrationSuccess: false,
      user_id: '',
      affiliate_id: null,
      lecture_id: '',
      lecture_datetime: '',
      lecture_description: '',
      presenter_bio: '',
      zoom_url: '',
      join_url: '',
      video_upload_url: '',
      question_for_speaker: '',
      isLoading: false,
      displayFormDialog: false,
      postalCodeRegex: /([A-Za-z][0-9]){3}/,
      postalCodeError: false,
      provinceError: false,
      professionError: false,
      isLoading: false,
      loadingRegistrants: false,
      feedback_form: '',
      starValue1: 0,
      starValue2: 0,
      starValue3: 0,
      starValue4: 0,
      starValue5: 0,
      selectedOption: 'No',
      reason_for_bias: '',
      lectureCreditFormSubmitted: false,
      displayVideoDialog: false,
      overlayHidden: false,
      options: {},
      playerReady: false,
      allProvinces: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'United States'],
      allProfessions: ['Physician', 'Nurse Practitioner', 'Other Healthcare Profession', 'Non Healthcare Profession'],
      displayAccountSetupDialog: false,
      attemptedRegisterForLecture: false,
      attemptedClaimLectureCredit: false,
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'loggedInUser', 'lectures', 'selectedLecture', 'selectedLectureMaterialFiles', 'lectureRegistrantsCount', 'isCme', 'isPreboarding']),
    getDialogWidth() {
      return window.innerWidth > 1200 ? { width: '70vw' } : { width: '90vw' };
    },
    cmeFormAvailable() {
      if (!this.isUpcomingLecture && this.cme_credit_available && !this.isArchivedLecture) {
        return true;
      } else {
        return false;
      }
    },
    userHasRegisteredForLecture() {
      // TODO: add zoom registration link to backend table
      // if (this.loggedInUser) {
      //   return this.selectedLecture.lecture_registrations.find((lecture_registrations) => lecture_registrations.user_id === this.loggedInUser.id);
      // }
    },
    isCurrentLecture() {
      let lectureEndTime = moment.utc(this.lecture_datetime).add(60, 'minutes');
      return moment.utc().isBetween(moment.utc(this.lecture_datetime), lectureEndTime);
    },
    isUpcomingLecture() {
      return moment().isBefore(moment.utc(this.lecture_datetime).add(30, 'minutes').local());
    },
    pastLectures: function () {
      return this.lectures.filter((lecture) => {
        return moment().isAfter(moment.utc(lecture.lecture_datetime).local()) > 0;
      });
    },
    isArchivedLecture() {
      return moment().subtract(1, 'years').isAfter(moment.utc(this.lecture_datetime).local());
    },
    archiveDate() {
      return moment(this.lecture_datetime).add(12, 'months').format('MMMM DD, YYYY');
    },
    showArchiveDate() {
      if (!this.isArchivedLecture) {
        return moment().subtract(11, 'months').isAfter(moment.utc(this.lecture_datetime).local());
      }
    },
  },
  methods: {
    ...mapActions(['toggleLectureBookmark', 'getAllLectures', 'getLectureById', 'createCmeCredit', 'getLectureMaterialFilesByLectureId', 'downloadLectureMaterialFile', 'registerUserForZoomLecture', 'createAudit', 'getLectureRegistrantsCount', 'updateUserAccountInfo']),
    ...mapMutations(['setIsLecturePlaying']),
    toggleLectureBookmarkOnClick() {
      this.toggleLectureBookmark({ lecture_id: this.lecture_id, user_id: this.loggedInUser.id });
      this.bookmarked = !this.bookmarked;
      if (this.bookmarked) {
        this.$toast.add({
          severity: 'success',
          summary: 'Lecture Bookmarked',
          life: 8000,
        });
      }
    },
    openZoomWebinarOnClick() {
      this.$refs.zoomEmbedRef.getSignature();
    },
    openExternalZoomWebinarLink() {
      window.open(this.join_url, '_blank');
    },
    showZoomLectureRegistrationClick() {
      this.displayAccountSetupDialog = false;
      this.showZoomLectureRegistrationFormDialog = false;
      this.attemptedRegisterForLecture = true;
      if (this.loggedInUser) {
        if (!this.loggedInUser.role || (this.isCme(this.loggedInUser) && (!this.loggedInUser.profession || !this.loggedInUser.address.province))) {
          this.displayAccountSetupDialog = true;
        } else {
          this.showZoomLectureRegistrationFormDialog = true;
        }
      }
    },
    submitZoomLectureRegistrationClick() {
      this.showLectureRegistrationLoading = true;
      let province = 'Unknown';
      if (this.loggedInUser.address?.province) {
        province = this.loggedInUser.address?.province;
      } else if (this.loggedInUser.practice_province?.name) {
        province = this.loggedInUser.practice_province?.name;
      }
      this.registerUserForZoomLecture({
        first_name: this.loggedInUser.first_name,
        last_name: this.loggedInUser.last_name,
        email: this.loggedInUser.email,
        zip: this.loggedInUser.address.postal_code,
        province: province,
        profession: this.loggedInUser.profession ? this.loggedInUser.profession : this.loggedInUser.role,
        user_id: this.loggedInUser.id,
        lecture_id: this.lecture_id,
        affiliate_id: this.affiliate_id,
        question_for_speaker: this.question_for_speaker,
      })
        .then((response) => {
          if (!response.data.join_url) {
            this.showZoomLectureRegistrationFormDialog = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error ',
              detail: 'Something went wrong. Please contact support@virtualhallway.ca',
              life: 10000,
            });
          } else {
            this.join_url = response.data.join_url;
            this.showLectureRegistrationLoading = false;
            this.showLectureRegistrationSuccess = true;
            this.getLectureRegistrantsCount(this.zoom_url);
          }
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    validateProfession() {
      if (!this.user.profession) {
        this.professionError = true;
      } else {
        this.professionError = false;
      }
    },
    // validateProvince() {
    //   // TODO: fix with other backend issue from registration
    //   // console.log(this.user.address.province);
    //   if (this.user.address.province === null) {
    //     this.provinceError = true;
    //   } else {
    //     this.provinceError = false;
    //   }
    // },
    validatePostalCode() {
      if (this.user.address.postal_code === null) {
        this.postalCodeError = true;
      } else if (this.user.address.province == 'United States') {
        this.postalCodeError = false;
      } else {
        this.user.address.postal_code = this.user.address.postal_code.replace(/\s+/g, '');
        if (this.user.address.postal_code.length === 5 || (this.user.address.postal_code.length === 6 && this.postalCodeRegex.test(this.user.address.postal_code))) {
          this.postalCodeError = false;
        } else {
          this.postalCodeError = true;
        }
      }
    },
    redirectToLoginWithLectureId() {
      if (this.$route.query.ref && this.$route.query.ref > 0) {
        this.$router.push('/login/?lec=' + this.lecture_id) + '&br=cme' + '&ref=' + this.$route.query.ref;
      } else {
        this.$router.push('/login/?lec=' + this.lecture_id + '&br=cme');
      }
    },
    redirectToRegistrationWithLectureId() {
      if (this.$route.query.ref && this.$route.query.ref > 0) {
        this.$router.push('/registration/?lec=' + this.lecture_id + '&br=cme' + '&ref=' + this.$route.query.ref);
      } else {
        this.$router.push('/registration/?lec=' + this.lecture_id + '&br=cme');
      }
    },
    showLoginModal() {
      this.$refs.loginModal.openModal();
    },
    onReady() {
      this.playerReady = true;
    },
    onEnded() {
      this.setIsLecturePlaying(false);
    },
    onPause() {
      this.setIsLecturePlaying(false);
    },
    onPlay() {
      this.setIsLecturePlaying(true);
    },
    play() {
      if (this.loggedInUser) {
        this.createAudit({ user_id: this.loggedInUser.id, auditable_type: 'App\\Models\\Lecture', auditable_id: this.selectedLecture.id, event: 'watched', tags: 'User Watched On Demand Lecture' })
          .then(() => {})
          .catch(() => {});
      }
      this.$refs.player.play();
    },
    playAndHideOverlay() {
      this.overlayHidden = true;
      this.play();
      this.mixpanel.track('CME - Clicked Watch Lecture Button', { lectureTitle: this.lecture_title, presenterName: this.presenter_name, cmeCreditAvailable: this.cme_credit_available, isUpcomingLecture: this.isUpcomingLecture, cmeCreditAmount: this.cme_credit_amount });
    },
    autoplayVideo() {
      setTimeout(() => {
        this.overlayHidden = true;
        //User needs to interact with the document to autoplay the video
        this.play();
        setTimeout(() => {
          this.mixpanel.track('CME - Clicked Watch Lecture Button', { lectureTitle: this.lecture_title, presenterName: this.presenter_name, cmeCreditAvailable: this.cme_credit_available, isUpcomingLecture: this.isUpcomingLecture, cmeCreditAmount: this.cme_credit_amount });
        }, 40000);
      }, 2000);
    },
    scrollToLectureVideo() {
      const player = this.$refs.videoPlayer;
      player.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
    },
    claimCMECreditOnClick() {
      this.mixpanel.track('CME - Clicked Claim CME Credit Button', { lectureTitle: this.lecture_title, presenterName: this.presenter_name, cmeCreditAvailable: this.cme_credit_available, isUpcomingLecture: this.isUpcomingLecture, cmeCreditAmount: this.cme_credit_amount });
      this.displayAccountSetupDialog = false;
      this.displayFormDialog = false;
      this.attemptedClaimLectureCredit = true;
      if (this.loggedInUser) {
        if (!this.loggedInUser.role || (this.isCme(this.loggedInUser) && (!this.loggedInUser.profession || !this.loggedInUser.address.province))) {
          this.displayAccountSetupDialog = true;
        } else {
          this.displayFormDialog = true;
        }
      } else {
        this.showLoginModal();
      }
    },
    scrollToFeedbackForm() {
      const feedbackForm = this.$refs.feedbackForm;
      feedbackForm.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
    },
    submitFeedback() {
      const feedback_form = JSON.stringify({
        bias: this.selectedOption,
        reason: this.reason_for_bias,
        starValue1: this.starValue1,
        starValue2: this.starValue2,
        starValue3: this.starValue3,
        starValue4: this.starValue4,
        starValue5: this.starValue5,
      });
      this.createCmeCredit({
        feedback_form: feedback_form,
        user_id: this.loggedInUser.id,
        creditable_id: this.lecture_id,
        creditable_type: 'App\\Models\\Lecture',
      })
        .then(() => {
          this.lectureCreditFormSubmitted = true;
          this.scrollToFeedbackForm();
          this.$toast.add({
            severity: 'success',
            summary: 'Feedback Submitted',
            detail: 'Your CME credit has been emailed to you for download',
            life: 5000,
          });
          this.mixpanel.track('CME - Clicked Submit Feedback Button', { lectureTitle: this.lecture_title, presenterName: this.presenter_name, cmeCreditAvailable: this.cme_credit_available, isUpcomingLecture: this.isUpcomingLecture, cmeCreditAmount: this.cme_credit_amount });
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    setupShowedLecture() {
      return new Promise((resolve, reject) => {
        this.getLectureById(this.$route.params.lectureId)
          .then(() => {
            // this.$route.params.pretty_url = this.selectedLecture.pretty_url;
            this.bookmarked = this.selectedLecture.bookmarked;
            this.lecture_id = this.selectedLecture.id;
            this.lecture_datetime = this.selectedLecture.lecture_datetime;
            this.lecture_title = this.selectedLecture.lecture_title;
            this.lecture_description = this.selectedLecture.lecture_description;
            this.lecture_image_location = this.selectedLecture.lecture_image_location;
            this.lecture_description = this.selectedLecture.lecture_description;
            this.lecture_attachment_location = this.selectedLecture.lecture_attachment_location;
            this.presenter_name = this.selectedLecture.presenter_name;
            this.presenter_specialty = this.selectedLecture.presenter_specialty;
            this.presenter_bio = this.selectedLecture.presenter_bio;
            this.presenter_image_location = this.selectedLecture.presenter_image_location;
            this.zoom_url = this.selectedLecture.zoom_url;
            this.video_upload_url = this.selectedLecture.video_upload_url;
            this.cme_credit_available = this.selectedLecture.cme_credit_available;
            this.cme_credit_amount = this.selectedLecture.cme_credit_amount;
            this.cme_session_id = this.selectedLecture.cme_session_id;
            this.lecture_published = this.selectedLecture.lecture_published;
            this.total_views = this.selectedLecture.total_views;
            document.title = this.selectedLecture.lecture_title;

            if (this.affiliate_id) {
              this.mixpanel.track('CME Page - ' + this.lecture_id, { affiliate_id: this.affiliate_id });
            }

            // TODO: Handle this on backend from relationship query
            if (this.loggedInUser && this.selectedLecture.lecture_registrations.length > 0 && this.isUpcomingLecture) {
              let userRegisteredForLecture = this.selectedLecture.lecture_registrations.filter((lecture) => lecture.user_id == this.loggedInUser.id && lecture.lecture_id == this.selectedLecture.id);
              this.join_url = userRegisteredForLecture[0]?.join_url;
            }

            // TODO: Remove this function entirely from back and frontend
            // if (this.selectedLecture.zoom_url && this.loggedInUser) {
            //   this.loadingRegistrants = true;
            //   this.getLectureRegistrantsCount(this.zoom_url)
            //     .then(() => {
            //       this.loadingRegistrants = false;
            //     })
            //     .catch(() => {});
            // }
            this.isLoading = false;

            this.getLectureMaterialFilesByLectureId({
              lecture_id: this.selectedLecture.id,
            });
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      });
    },
    clickDownloadFile(id) {
      if (this.loggedInUser) {
        this.downloadLectureMaterialFile({
          id: id,
        })
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'File Downloaded',
              detail: 'Please check your downloads folder to open.',
              life: 5000,
            });
          })
          .catch(() => this.showGenericErrorToast());
      } else {
        this.showLoginModal();
      }
    },
    userFinishedPreboarding() {
      if (this.attemptedClaimLectureCredit) {
        this.claimCMECreditOnClick();
      } else if (this.attemptedRegisterForLecture) {
        this.showZoomLectureRegistrationClick();
      }
    },
    preboardingDialogClosed() {
      this.attemptedClaimLectureCredit = false;
      this.attemptedRegisterForLecture = false;
    },
  },
  created() {
    this.isLoading = true;
    this.affiliate_id = this.$route.query.ref ? this.$route.query.ref : this.$route.query.affiliate_id;
    this.user = this.loggedInUser;
    if (this.loggedInUser?.role === 'gp' || this.loggedInUser?.role === 'specialist') {
      this.user.profession = 'Physician';
    } else if (this.loggedInUser?.role == 'np') {
      this.user.profession = 'Nurse Practitioner';
    }
    if (this.$route.query.showZoomLectureRegistrationDialog) {
      this.showZoomLectureRegistrationClick();
    }
    this.getAllLectures()
      .then(() => {
        this.setupShowedLecture()
          .then(() => {
            if (this.isLoggedIn && this.selectedLecture.video_upload_url) {
              this.autoplayVideo();
            }
            // display confirmation if user registers for lecture while signed out
            if (this.$route.query.lectureRegistrationSuccess == 'true') {
              this.$confirm.require({
                group: 'templating',
                message: 'Check your email for instructions on how to join the lecture.',
                header: 'Lecture Registration Successful',
                icon: 'pi pi-check',
                acceptLabel: 'Done',
                acceptClass: 'p-button-secondary',
                rejectLabel: 'Close',
                rejectClass: 'hidden p-button-secondary ',
                accept: () => {},
                reject: () => {},
              });
            }
          })
          .catch(() => {
            this.showGenericErrorToast();
          });
      })
      .catch(() => {
        this.showGenericErrorToast();
      });
  },
  unmounted() {
    this.setIsLecturePlaying(false);
  },
  watch: {
    $route(to, from) {
      if (to !== from && to.name.includes('SingleLecture')) {
        this.isLoading = true;
        this.setupShowedLecture()
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
      if (this.$route.query?.showZoomLectureRegistrationDialog) {
        this.showZoomLectureRegistrationClick();
      }
    },
  },
};
</script>

<style>
.pulsing-circle {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #ef5164;
  position: relative;
  left: 50%;
  margin-top: 13px;
  margin-left: 3px;
  -webkit-transition: height 0.25s ease, width 0.25s ease;
  transition: height 0.25s ease, width 0.25s ease;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.pulsing-circle:before,
.pulsing-circle:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ef5164;
}

.pulsing-circle:before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}
.pulsing-circle:after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.expandable {
  -webkit-transition: all 0.6s cubic-bezier(0.17, 0.65, 0.36, 1);
  -moz-transition: all 0.6s cubic-bezier(0.17, 0.65, 0.36, 1);
  -o-transition: all 0.6s cubic-bezier(0.17, 0.65, 0.36, 1);
  transition: all 0.6s cubic-bezier(0.17, 0.65, 0.36, 1);
  height: 0;
}
.expanded {
  -webkit-transition: all 0.6s cubic-bezier(0.17, 0.65, 0.36, 1);
  -moz-transition: all 0.6s cubic-bezier(0.17, 0.65, 0.36, 1);
  -o-transition: all 0.6s cubic-bezier(0.17, 0.65, 0.36, 1);
  transition: all 0.6s cubic-bezier(0.17, 0.65, 0.36, 1);
  height: 500px;
  max-height: 500px;
  margin-bottom: 40px;
}

.p-rating-icon {
  font-size: 1.4rem !important;
}
.video-player-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
}
.video-player-branded-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
}
.video-logo {
  max-width: 24rem;
}
.bio-tooltip .p-tooltip-text {
  width: 300px;
  background-color: rgb(227, 227, 227);
  color: #000000;
  font-size: 0.9rem;
}
.video-play-icon {
  font-size: 5rem;
}

@media (max-width: 500px) {
  .video-play-icon {
    font-size: 3rem;
  }
  .video-logo {
    max-width: 16rem;
  }
}
/* 
.readLess {
  height: 109px;
  overflow: hidden;
}
.read-more,
.read-less {
  background-color: green;
  display: inline-block;
  color: white;
} */
</style>
