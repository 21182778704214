<template>
  <s-facebook :window-features="windowFeatures" :share-options="shareOptionsFacebook" :use-native-behavior="useNativeBehavior" @popup-close="onClose" @popup-open="onOpen" @popup-block="onBlock" @popup-focus="onFocus">
    <i class="mr-2 text-gray-300 cursor-pointer pi pi-facebook hover:text-gray-600" style="font-size: 1.2rem"></i>
  </s-facebook>
  <s-twitter :window-features="windowFeatures" :share-options="shareOptionsTwitter" :use-native-behavior="useNativeBehavior" @popup-close="onClose" @popup-open="onOpen" @popup-block="onBlock" @popup-focus="onFocus">
    <i class="mr-2 text-gray-300 cursor-pointer pi pi-twitter hover:text-gray-600" style="font-size: 1.2rem"></i>
  </s-twitter>
  <s-linked-in :window-features="windowFeatures" :share-options="shareOptionsLinkedIn" :use-native-behavior="useNativeBehavior" @popup-close="onClose" @popup-open="onOpen" @popup-block="onBlock" @popup-focus="onFocus">
    <i class="mr-2 text-gray-300 cursor-pointer pi pi-linkedin hover:text-gray-600" style="font-size: 1.2rem"></i>
  </s-linked-in>
  <i v-if="!this.loggedInUser" @click="copyLinkForLectureRegistration()" class="text-gray-300 cursor-pointer pi pi-link hover:text-gray-600" style="font-size: 1.4rem"></i>
</template>

<script>
import { SFacebook } from 'vue-socials';
import { STwitter } from 'vue-socials';
import { SLinkedIn } from 'vue-socials';

export default {
  components: {
    SFacebook,
    STwitter,
    SLinkedIn,
  },
  props: ['selectedLecture'],
  data() {
    return {
      windowFeatures: {},
      shareOptionsFacebook: {
        url: window.location.href,
        quote: this.selectedLecture.lecture_title,
        hashtag: '#CME', // turn these into dynamic tags
      },
      shareOptionsTwitter: {
        url: window.location.href,
        text: this.selectedLecture.lecture_title,
        hashtags: ['#CME'],
        // via: 'twitterdev',
      },
      shareOptionsLinkedIn: {
        url: window.location.href,
      },
      useNativeBehavior: false,
    };
  },
  methods: {
    onClose() {},
    onOpen() {},
    onBlock() {},
    onFocus() {},
    copyLinkForLectureRegistration() {
      this.$toast.add({
        severity: 'success',
        summary: 'Link Copied',
        life: 1500,
      });
      navigator.clipboard.writeText(window.location.origin + '/registration/?lec=' + this.selectedLecture.id + '&br=cme');
    },
  },
};
</script>
<style></style>
